<template>
  <div data-layout-name="default">
    <TheHeader>
      <template #right>
        <button
          class="burger"
          type="button"
          aria-label="open menu"
          @click="toggleMobileMenu(true)"
        >
          <SvgBurger />
        </button>
      </template>
    </TheHeader>

    <BreakingNewsBanner v-if="isPageMayHaveBanner">
      <NuxtLink :to="localePath('/welcome-192tb-ootbi/website-banner/?utm_source=website-banner')">
        {{ $t('BreakingNewsBanner') }}
      </NuxtLink>
    </BreakingNewsBanner>

    <ClientOnly>
      <Teleport to="#overlay">
        <SidebarMobile
          :class="{ active: isMobileMenuOpened }"
          @close="toggleMobileMenu(false)"
        />
      </Teleport>
    </ClientOnly>

    <main>
      <slot />
    </main>

    <TheFooter/>
  </div>
</template>

<script setup lang="ts">
// store
import { useCommonStore } from '~/stores/common';
import { useVeeamOnStore } from '~/stores/veeamon';

// composable
import { storeToRefs } from 'pinia';
import { useLocalePath } from "#i18n";

// components
import TheHeader from '~/components/redesign/TheHeader.vue';
import TheFooter from '~/components/redesign/TheFooter.vue';
import SidebarMobile from '~/components/redesign/SidebarMobile.vue';
import BreakingNewsBanner from '~/components/redesign/BreakingNewsBanner.vue';

const localePath = useLocalePath();
const { setOverlay } = useCommonStore();
const { isPageMayHaveBanner } = storeToRefs(useVeeamOnStore());

const isMobileMenuOpened = ref(false);
const toggleMobileMenu = (value: boolean) => {
  isMobileMenuOpened.value = value;
};

watch(isMobileMenuOpened, visible => setOverlay({ visible }));
</script>

<style lang="scss">
@import "$/mixins/media";
@import "$/functions/token";

[data-layout-name="default"] {
  padding-top: 5.5rem;

  @include mobile {
    padding-top: 3.488125rem;
  }

  &:has([data-component-name="BreakingNewsBanner"]) {
    padding-top: calc(5.5rem + 3.25rem);

    @include mobile {
      padding-top: calc(3.488125rem + 2.6875rem);
    }
  }

  [data-component-name="TheHeader"] {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;

    button.burger {
      display: none;

      @include tablet {
        display: block;
      }
    }
  }

  main {}
}

[data-component-name="SidebarMobile"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateX(100%);
  transition: transform 0.15s ease-in-out;

  &.active {
    transform: translateX(0);
  }
}
</style>
